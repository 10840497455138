import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../../components/button"
import Container from "../../components/container"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SmallYellowBlob from "../../components/small-yellow-blob"
import EmailSignup from "../../components/email-signup"
import MasterClassEmailSignup from "../../components/master-class-email-signup"

const VideoCourse = () => {
  // const handleSignUp = e => {
  //   e.preventDefault()
  //   setLoading(true)
  //   var data = JSON.stringify({
  //     email,
  //     name,
  //     group: "70778989897057377",
  //   })

  // var config = {
  //   method: "post",
  //   url: "https://www.scottsschoolfordogs.com/.netlify/functions/subscribe",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   data: data,
  // }

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response))
  //       if (response?.status === 200) {
  //         setSubmitted(true)
  //         setLoading(false)
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //       setLoading(false)
  //     })
  // }

  return (
    <Layout>
      <article className="prose prose-headings:text-lightBlack prose-h3:font-crimson prose-h3:normal-case prose-h3:tracking-normal prose-a:text-primary max-w-none mx-auto prose-img:rounded-lg">
        <Container>
          <div className="relative not-prose overflow-x-clip	">
            <div
              className="absolute top-[10%] lg:top-[15%] left-[0%] lg:left-[10%] w-[140%] lg:w-[80%] h-[100%] lg:h-[110%]"
              style={{ zIndex: 0 }}
            >
              <SmallYellowBlob />
            </div>
            <div
              className="pt-6 lg:pt-24  px-2 lg:px-0 pb-0 max-w-3xl  mx-auto text-center relative"
              style={{ zIndex: 30 }}
            >
              <h3 className="">Video Course</h3>

              <h1 className="mb-2 sm:text-5xl mt-8 text-lightBlack tracking-normal	">
                My Dog is Reactive: Now What?
              </h1>
              <h2 className="mb-12 normal-case max-w-xl mx-auto text-lg sm:text-xl tracking-normal font-normal text-gray-500">
                Rehabilitate your barking and lunging dog using force-free
                training methods full video course
              </h2>
              <hr className="border border-primary max-w-xs w-24 mx-auto" />

              <div className="rounded-lg mt-12 lg:mt-12">
                {/* <video width="900" height="500" controls poster={Poster}>
                  <source src={IntroVideo} type="video/mp4" />
                  Your browser does not support the video element. Kindly update
                  it to latest version.
                </video> */}
                <iframe
                  style={{
                    aspectRatio: "16 / 9",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  // width="560"
                  // height="500"
                  src="https://www.youtube-nocookie.com/embed/deVhkyu6xrg"
                  title="Intro Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div
            className="flex relative not-prose items-center justify-center mt-16 mb-16 mx-2"
            style={{ zIndex: 30 }}
          >
            <div className="">
              <StaticImage
                src="../../images/avatar.png"
                className="z-10 "
                alt="Taylor and Scott high fiving after some great dog training in Boise"
                layout="constrained"
                width={120}
                height={120}
              />
            </div>
            <div className="flex flex-col ml-6">
              <h3 className="mb-0 te font-medium">Taught By</h3>
              <Link to="/about">
                <h2 className=" text-xl sm:text-3xl mb-0 text-lightBlack">
                  Taylor Wyllie, KPA CTP
                </h2>
              </Link>
              <p>Certified professional dog trainer and reactive dog owner</p>
            </div>
          </div>
        </Container>

        <Container>
          <div
            className="text-lightBlack mt-8 mb-4 max-w-xl mx-auto relative px-4 xl:px-0"
            style={{ zIndex: 31 }}
          >
            <div
              className="hidden md:block  absolute bottom-[60%] -left-[40%] lg:-left-[85%] w-[100%] rotate-[150deg]	 "
              style={{ zIndex: 0 }}
            >
              <SmallYellowBlob />
            </div>
            <div className="relative">
              <h2 className="text-4xl">What You Will Learn in This Course</h2>
              <p>
                I&apos;ve taken hours of experience, research, and
                evidence-based studies and condensed them into simple, concrete
                lessons to help you and your dog thrive.
              </p>
              <ul className="list-none list-outside pl-0	">
                <li className="pb-0 pl-0 mb-0">
                  <h3 className="mb-0">Reduce your dog’s outbursts</h3>
                  <p className="text-gray-500">
                    Understand how to change your dog’s underlying emotions
                    toward their triggers, so their reactions become less
                    severe, less frequent, or disappear altogether
                  </p>
                </li>
                <li className="mb-0 mt-0 pl-0">
                  <h3 className="mb-0 mt-0">
                    Manage your dog’s reactions as they happen
                  </h3>
                  <p className="text-gray-500">
                    Learn tools to help you calmly, confidently, and effectively
                    manage your dog as they react to their triggers
                  </p>
                </li>
                <li className="mb-0 mt-0 pl-0">
                  <h3 className="mb-0 mt-0">Stop spinning your wheels</h3>
                  <p className="text-gray-500">
                    Feel empowered with specific action steps to follow on your
                    rehabilitation journey, so you don’t have to keep going down
                    the internet rabbit hole with all of its conflicting advice
                  </p>
                </li>
                <li className="mb-0 mt-0 pl-0">
                  <h3 className="mb-0 mt-0">
                    Heal your relationship with your dog
                  </h3>
                  <p className="text-gray-500">
                    Gain a better understanding of why your dog barks and lunges
                    at their triggers, so you don’t have to feel angry or
                    resentful toward your dog
                  </p>
                </li>
                <li className="mb-0 mt-0 pl-0">
                  <h3 className="mb-0 mt-0">Solve future problems</h3>
                  <p className="text-gray-500">
                    Learn training and rehabilitation concepts that you can
                    apply when future problems arise
                  </p>
                </li>
                <li className="mb-0 mt-0 pl-0">
                  <h3 className="mb-0 mt-0">Don’t compromise your values</h3>
                  <p className="text-gray-500">
                    Rehabilitate your reactive dog using positive-reinforcement
                    and force-free based training techniques
                  </p>
                </li>
              </ul>
              <MasterClassEmailSignup />
              <h2 className="text-4xl">The Story Behind the Course</h2>
              <p>
                I created My Dog is Reactive because it was the class I needed
                after I adopted my own reactive dog, Scott. I spent months of my
                life with Scott feeling overwhelmed, defeated, lonely, and
                frustrated. I tried to help him but spent too much time spinning
                my wheels, getting nowhere.
              </p>
              <p>
                <strong>I was running in circles trying to…</strong>
              </p>
              <ul>
                <li>
                  Avoid embarrassing displays of Scott’s reactivity (mainly
                  barking and lunging at other dogs)
                </li>
                <li>
                  Navigate the conflicting advice on the internet in order to
                  “train the reactivity out of him”
                </li>
                <li>
                  Explain to family and friends why I couldn’t do ‘normal’
                  actives with my dog (like take him to the dog park or leave
                  him with a friend to go on vacation)
                </li>
                <li>Not lose my mind</li>
              </ul>

              <p>
                Fast forward a few years, and while my dog is still dog
                reactive, he&apos;s lightyears ahead of where he used to be and
                getting better every single day.
              </p>
              <p>
                <strong>Now I…</strong>
              </p>
              <ul>
                <li>
                  Can take my reactive dog, Scott, on peaceful walks and hikes
                </li>
                <li>
                  Comfortably leave him in the care of a pet sitter for
                  much-needed vacations
                </li>
                <li>Have my life back again</li>
              </ul>
              <p>
                I was so inspired by Scott&apos;s progress that I decided to
                make a huge career change to become a{" "}
                <Link to="/about">professional dog trainer</Link>. I graduated
                from the prestigious Karen Pryor Academy and launched my
                business.
              </p>
              <p>
                I&apos;ve taken all that I&apos;ve learned rehabilitating Scott,
                through my professional program, and working with clients and
                put it into My Dog is Reactive: Now What?
              </p>

              <p>
                Because, while it may seem hopeless now, it&apos;s possible to
                cultivate a happy, peaceful life with your reactive dog.
                Seriously.
              </p>
            </div>
            <StaticImage
              src="../../images/IMG_0517.jpg"
              className="z-10 rounded-lg order-1 lg:order-3 mb-0 mt-6 "
              alt="Taylor and Scott high fiving after some great dog training in Boise"
              layout="constrained"
              width={900}
              height={500}
            />
            <h2 className="relative text-4xl text-center">What You Get</h2>
            <div className="grid grid-col-1 sm:grid-cols-2">
              <div>
                <h3 className="text-center mt-0">The Course Features</h3>
                <ul className="relative">
                  <li>25 Videos</li>
                  <li>4+ hours of content</li>
                  <li>10 homework sheets</li>
                  <li>4 trackers</li>
                  <li>And more!</li>
                </ul>
              </div>
              <div>
                <h3 className="text-center mt-0">You Will Gain</h3>
                <ul className="relative">
                  <li>The skills to prevent your dog&apos;s reactions</li>
                  <li>
                    The knowledge to address the root cause of your dog&apos;s
                    reactivity
                  </li>
                  <li>The confidence to handle problems as they arise</li>
                </ul>
              </div>
            </div>

            <h2 className="relative text-4xl text-center">
              How To Get Started
            </h2>
            <ol className="relative">
              <li>
                <strong>Buy the course...</strong> and start learning right away
                with instant access.
                <span> </span>
              </li>
              <li>
                <strong>Watch the lessons...</strong> and immediately start
                applying what you learn, taking concrete steps toward creating a
                calmer, happier life with your dog.
              </li>
              <li>
                <strong>Guide your dog with confidence...</strong> and watch as
                reactive behaviors reduce.
              </li>
            </ol>
            <div className="my-14 flex justify-center not-prose">
              {/* <Button
                onClick={() =>
                  navigate(
                    `https://courses.scottsschoolfordogs.com/my-dog-is-reactive-now-what`
                  )
                }
                // onClick={() => scrollTo("#sign-up-video-course")}
                style={{ width: "fit-content", margin: "0px 5px " }}
                buttonType="outline"
              >
                Preview Course
              </Button> */}
              <Button
                onClick={() =>
                  navigate(
                    `https://scotts-school-for-dogs.disco.co/p/my-dog-is-reactive-now-what/register`
                  )
                }
                // onClick={() => scrollTo("#sign-up-video-course")}
                style={{ width: "fit-content", margin: "0px 5px" }}
              >
                Buy now
              </Button>
            </div>
            {/* TODO: ADD this awesome card back in when it&apos;s */}
            {/* <div className="my-14 flex justify-center not-prose">
              <div
                className="flex flex-col rounded-2xl bg-white p-6 md:p-10 max-w-xl w-full text-lightBlack order-2 "
                style={{
                  filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15))",
                }}
              >
                <h4 className="font-crimson text-3xl font-semibold mb-1">
                  My Dog is Reactive: Now What?
                </h4>
                <p className="w-fit text-lg mb-6">$399</p>
                
                <p className="pb-1">Includes:</p>
                <ul className="mb-8">
                  {INCLUDES.map(benefit => (
                    <li className="flex items-top pb-2  " key={benefit}>
                      <div className="mt-1 w-7">
                        <CheckMark />
                      </div>
                      <span className="flex-1">{benefit}</span>
                    </li>
                  ))}
                </ul>
                <Button
                  // onClick={() => navigate(`/services/`)}
                  onClick={() => scrollTo("#sign-up-video-course")}
                  style={{ width: "fit-content", margin: "0px auto" }}
                >
                  Enroll Now
                </Button>
              </div>
            </div> */}

            <h2>What&apos;s Your Time Worth?</h2>
            <p>
              I&apos;ll be honest, you could try to research the content of this
              course on your own. You could read stacks dog training books and
              comb through hours of research. You could try to put together
              information from dog training YouTube channels, blogs, and TikTok
              videos. But at the end of the day, you&apos;d still be thinking,
              “I need a plan!”
            </p>
            <p>
              I designed the course to give you concrete steps to take, which
              you can start applying right away. Every lesson builds on the
              previous lesson, giving you exactly the information you need to
              succeed: nothing less, nothing more. No more confusion. No more
              overwhelm.
            </p>
            <blockquote>
              <p>
                In the My Dog is Reactive: Now What? course, we&apos;ve done the
                work for you.
              </p>
            </blockquote>
            <h2 className="text-3xl text-center">Frequently Asked Questions</h2>
            <h3 className="text-2xl mb-0">
              How long do I have access to the course?
            </h3>
            <p className="text-gray-600 ">
              You will have access to the course materials for life. So, you can
              continue to come back to the videos and course materials as the
              need arises.
            </p>
            <h3 className="text-2xl mb-0">
              I&apos;m skeptical about positive reinforcement training.
              Doesn&apos;t that just mean I&apos;ll let my dog get away with bad
              behaviors?
            </h3>
            <p className="text-gray-600 ">
              Positive-reinforcement based training focuses on teaching your dog
              what to do, rather than what not to do. It does not give your dog
              a free pass for behaviors you aren&apos;t comfortable with.
            </p>
            <h3 className="text-2xl mb-0">
              I&apos;m looking for a quick fix to get my dog to stop barking and
              lunging today. Is this course a good fit for me?
            </h3>
            <p className="text-gray-600 ">
              Depending on the dog and situation, rehabilitating a reactive dog
              can take months, years, or even your dog&apos;s entire lifetime.
              Real rehabilitation focuses on the underlying emotions your dog
              experiences when seeing his or her triggers for real, lasting
              results. This is hard work akin to a person working through
              anxiety with a trusted therapist. That said, by actively managing
              your dog&apos;s environment and behavior, you will get relief
              right away.
            </p>
          </div>
          <div className="shadow py-12 bg-lightYellow rounded-2xl mt-12 sm:mt-24 mb-24 sm:mb-32 mx-2 px-2 max-w-2xl sm:mx-auto">
            {/* {submitted && (
              <>
                <h2 className="text-center text-4xl sm:text-5xl mb-2 mt-0 ">
                  Thanks for signing up!
                </h2>
                <p className="text-center text-gray-500">
                  Check your email to confirm your email address
                </p>
              </>
            )} */}

            <>
              <h2 className="text-center text-4xl sm:text-5xl mb-2 mt-0 ">
                Enroll Now
              </h2>
              <p className="text-center text-gray-500 mb-0">
                Get instant access to the entire course
              </p>
              <p className="text-center text-2xl font-bold text-primary mt-4 mb-0 ">
                $200
              </p>

              <div className="flex flex-col items-center justify-center">
                <ul className="">
                  <li>25 Videos</li>
                  <li>4+ hours of content</li>
                  <li>10 homework sheets</li>
                  <li>4 trackers</li>
                  <li>And more!</li>
                </ul>
                <div>
                  {/* <Button
                    onClick={() =>
                      navigate(
                        `https://courses.scottsschoolfordogs.com/my-dog-is-reactive-now-what/`
                      )
                    }
                    // onClick={() => scrollTo("#sign-up-video-course")}
                    style={{ width: "fit-content", margin: "0px 10px 0px 0px" }}
                    buttonType="outline"
                  >
                    Preview Course
                  </Button> */}
                  <Button
                    onClick={() =>
                      navigate(
                        `https://scotts-school-for-dogs.disco.co/p/my-dog-is-reactive-now-what/register`
                      )
                    }
                    // onClick={() => scrollTo("#sign-up-video-course")}
                    style={{ width: "fit-content", margin: "0px" }}
                  >
                    Buy now
                  </Button>
                </div>

                {/* <form
                    className="flex flex-col items-center justify-center"
                    onSubmit={handleSignUp}
                  >
                    <div className="flex flex-col sm:flex-row w-full">
                      <div className="mx-2 mb-4 sm:mb-8">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="name"
                        >
                          Name
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="name"
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>

                      <div className="mx-2">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="Email"
                        >
                          Email
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 mb-6 sm:mb-0 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="email"
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <button
                        className={`bg-primary hover:bg-lightPrimary rounded-md py-2 font-semibold px-6 text-white `}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Subscribing..." : "Sign me up!"}
                      </button>
                    </div>
                    <span className="text-center text-gray-500 text-sm  mt-4">
                      You&apos;re signing up to receive emails from Scott&apos;s School
                      For Dogs
                    </span>
                  </form> */}
              </div>
            </>
          </div>
          <MasterClassEmailSignup />
        </Container>
        {/* Final CTA */}
      </article>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="My Dog is Reactive: Now What? | Video Course"
    description="This in-depth self-paced course will help you rehabilitate your barking and lunging dog using the most up-to-date force-free training methods."
  />
)

export default VideoCourse
